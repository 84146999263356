function SkillsBar(props) {
  return (
    <div className="mt-2" style={{}}>
      <div className="text-start">
        <h6 className="mb-0">{props.skillTitle}</h6>
      </div>
      <div className="progress" style={{ height: '1rem' }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${props.skillRate}`,
            backgroundColor: '#0b192c',
            color: '#0b192c',
          }}
          aria-valuenow={props.skillValue}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {props.skillRate}
        </div>
      </div>
    </div>
  );
}

export default SkillsBar;
