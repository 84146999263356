import MainNavigationBar from '../components/MainNavigationBar';
import classes from './MainLayout.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function MainLayout(props) {
  return (
    <div
      // class="container-fluid"
      className="container-fluid mx-0 px-0 w-100"
      // style={{ backgroundColor: 'red' }}
    >
      <Row>
        <Col>
          <MainNavigationBar />
        </Col>
      </Row>
      <Row className="mx-0 px-0">
        <Col className="mx-0 px-0">{props.children}</Col>
      </Row>
    </div>
  );
}
