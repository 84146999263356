import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaBars } from 'react-icons/fa';
import { useState } from 'react';
// import NavDropdown from 'react-bootstrap/NavDropdown';

function MainNavigationBar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className=" fixed-top"
      style={{ backgroundColor: 'white' }}
    >
      <Container>
        <Navbar.Brand style={{ color: '#0b192c' }} href="#home">
          Kokkales
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleToggle}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white', // Change the color of the button itself
            position: 'relative',
            transition: 'transform 0.3s ease', // Smooth transition for the transform
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '25px',
              height: '3px',
              backgroundColor: 'black', // Black color for the top bar
              borderRadius: '3px',
              transition: 'transform 0.3s ease, opacity 0.3s ease', // Smooth transition for the bar
              transform: isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'none',
              opacity: isOpen ? 0 : 1,
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              top: '8px',
              left: '0',
              width: '25px',
              height: '3px',
              backgroundColor: 'black', // Black color for the middle bar
              borderRadius: '3px',
              transition: 'opacity 0.3s ease',
              opacity: isOpen ? 0 : 1, // Hide the middle bar when open
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              top: '16px',
              left: '0',
              width: '25px',
              height: '3px',
              color: 'black',
              backgroundColor: 'black', // Black color for the bottom bar
              borderRadius: '3px',
              transition: 'transform 0.3s ease, opacity 0.3s ease',
              transform: isOpen
                ? 'rotate(-45deg) translate(5px, -5px)'
                : 'none',
              opacity: isOpen ? 1 : 0,
            }}
          ></div>
        </Navbar.Toggle>
        <Navbar.Collapse
          style={{ color: '#0b192c' }}
          id="responsive-navbar-nav"
        >
          <Nav style={{ color: '#0b192c' }} className="me-auto">
            <Nav.Link style={{ color: '#0b192c' }} href="#home">
              Home
            </Nav.Link>
            <Nav.Link style={{ color: '#0b192c' }} href="#about">
              AboutMe
            </Nav.Link>
            {/* <Nav.Link href="#education">Education</Nav.Link> */}
            {/* <Nav.Link href="#experience">XPerience</Nav.Link> */}
            <Nav.Link style={{ color: '#0b192c' }} href="#services">
              Services
            </Nav.Link>
            <Nav.Link style={{ color: '#0b192c' }} href="#skills">
              Skills
            </Nav.Link>
            <Nav.Link style={{ color: '#0b192c' }} href="#projects">
              Projects
            </Nav.Link>
            <Nav.Link style={{ color: '#0b192c' }} href="#research">
              Research
            </Nav.Link>
            {/* <Nav.Link href="#contact">Contact</Nav.Link> */}
          </Nav>
          <Nav>
            <Nav.Link style={{ color: '#0b192c' }} href="#contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavigationBar;
