// import classes from './ContactPage.module.css';
// import MainLayout from '../layouts/MainLayout';
import ContactForm from '../components/forms/ContactForm';
import linkedin from '../images/linkedin.png';
// import instagram from '../images/instagram.png';
import github from '../images/github-sign.png';
import Row from 'react-bootstrap/esm/Row';
// import Project from '../components/projects/Project';
// import battery from '../images/battery.png';

function ContactPage(props) {
  return (
    <div
      id="contact"
      className="container-fluid mx-0 pb-4 text-center d-flex flex-column align-items-center"
    >
      <Row className="pb-0 mx-0 justify-content-center align-items-center w-100">
        {/* First column: Contact Form */}
        <div className="col-12 col-md-8 col-lg-4 mb-3">
          <ContactForm />
        </div>

        {/* Second column: Social Links and Email */}
        {/* Second column: Social Links and Email */}
        <div className="col-12 col-md-8 col-lg-6 text-center">
          <Row className="justify-content-center align-items-center my-4">
            {/* LinkedIn Link */}
            <a
              href="https://www.linkedin.com/in/konstantinos-kokkalis-806821221/"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3 d-flex justify-content-center align-items-center text-dark text-decoration-none mb-4"
            >
              <img
                src={linkedin}
                alt="LinkedIn"
                className="img-fluid"
                style={{ width: '50px', height: '50px' }}
              />
              <span className="ms-2 fs-5">Konstantinos Kokkalis</span>
            </a>

            {/* GitHub Link */}
            <a
              href="https://github.com/Kokkales"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3 d-flex justify-content-center align-items-center text-dark text-decoration-none mb-4"
            >
              <img
                src={github}
                alt="GitHub"
                className="img-fluid"
                style={{ width: '50px', height: '50px' }}
              />
              <span className="ms-2 fs-5">Kokkales</span>
            </a>

            {/* Email Address */}
            <a
              href="mailto:kokkaleskonstantinos@gmail.com"
              className="mx-3 d-flex justify-content-center align-items-center text-dark text-decoration-none mb-4"
              style={{ fontSize: '16px' }}
            >
              <i
                className="bi bi-envelope me-2"
                style={{ fontSize: '24px' }}
              ></i>
              <span className="fs-5">kokkaleskonstantinos@gmail.com</span>
            </a>
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default ContactPage;
