// import AboutCircle from '../components/projects/AboutCircle';
import classes from './AboutPage.module.css';
// import cv from '../otherFiles/CV KOKKALIS KONSTANTINOS.pdf';
// import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import myFace from '../images/IMG_20240914_103440.jpg';
import Subtitle from '../components/Subtitle';
import Image from 'react-bootstrap/esm/Image';

function AboutPage(props) {
  return (
    <div
      id="about"
      className="container-fluid mx-0 pb-4 text-center justify-content-top align-items-center"
      style={{ backgroundColor: '#0b192c' }}
    >
      <Row className="pb-0 mx-0" style={{}}>
        <Col className="px-0 mx-0">
          <Subtitle isSec="1" title="About Me" subtitle="who am I"></Subtitle>
        </Col>
      </Row>
      <Row className=" pb-0 mx-0 justify-content-center align-items-center">
        {/* First column: Container for image */}
        <div
          className=" mx-2 px-0 col-12 col-md-12 col-lg-3"
          style={{ width: 'auto' }}
        >
          <div // Use Bootstrap classes for responsiveness
            style={{ width: 'auto' }}
          >
            {/* <Image
              src={myFace}
              alt="Konstantinos Kokkalis"
              // className="w-10 h-10"
              // style={{
              //   width: '25rem',
              //   height: '25rem',
              //   borderRadius: '1rem',
              // }}
              className={classes.responsiveImage}
            /> */}
          </div>
        </div>

        {/* Second column: Text column */}
        <div
          className="col-12 col-md-12 col-lg-6 text-center align-items-center justify-content-center"
          style={{}}
        >
          <Row className="text-center justify-content-center align-items-center">
            <p style={{ color: 'white' }}>
              I am a 22-year-old programming enthusiast from Greece with a
              passion for technology and innovation. My journey in the world of
              coding started back in high school, where I discovered my love for
              solving problems and building applications.
            </p>
            <p style={{ color: 'white' }}>
              I completed my Bachelor's degree in Informatics and Telematics at
              Harokopio University, where I honed my skills through various
              academic projects and hands-on experience. I'm particularly proud
              of my thesis, which was not only the culmination of my studies but
              also got published as a research paper in the prestigious IEEE
              conference—a significant milestone in my academic career.
            </p>
            <p style={{ color: 'white' }}>
              Currently, I'm serving in the military, where I continue to refine
              my discipline and problem-solving skills. Even during this time, I
              stay connected to the tech world and look forward to applying my
              knowledge in future professional challenges.
            </p>
          </Row>
          <Row className="text-center justify-content-center align-items-center">
            <blockquote>
              <p className="lead" style={{ color: 'white' }}>
                “The only way to do great work
                <br />
                is to love what you do.“
              </p>
              <cite style={{ color: 'white' }}>Steve Jobs</cite>
            </blockquote>
          </Row>
        </div>
      </Row>
    </div>
  );
}
export default AboutPage;
