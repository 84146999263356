import React from 'react';
import MainLayout from '../layouts/MainLayout';
import Project from '../components/projects/Project';
import divorcePdf from '../otherFiles/divorceService.pdf';
import cq from '../otherFiles/circularQueue.pdf';
import dataMiningPdf from '../otherFiles/dataMiningDocumentation.pdf';
import Container from 'react-bootstrap/Container';
import Subtitle from '../components/Subtitle';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons for arrows
import forecastImg from '../images/forecast.png';
import contractImg from '../images/contract.png';
import threadImg from '../images/knitting.png';
import testingImg from '../images/testing.png';
import travelImg from '../images/world.png';
import dataStructureImg from '../images/data-structure.png';
import dataVisualizationImg from '../images/data-visualization.png';
import githubImg from '../images/github.png';

// Custom hook to detect screen width for responsive chunks
import { useMediaQuery } from 'react-responsive';

function ProjectPage() {
  // Use media queries to change chunk size based on screen width
  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const projects = [
    {
      image: forecastImg,
      title: 'Oscar predictions - Data Mining',
      description:
        'Predicting Oscar winners by training various models Like KNN, Gradient Booster, Tree classifier, Logistic regression',
      skills: ['Python', 'Scikit-learn', 'pandas', 'numpy'],
      link: 'https://github.com/Kokkales/Oscars---Data-Mining',
      pdfFile: dataMiningPdf,
      pdfName: 'Documentation',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: testingImg,
      title: 'Resource Utilization Calculator',
      description:
        'Calculating the resources utilization in my system while sending different requests, in different size batches and with different frequencies to a restAPI.',
      skills: ['Python'],
      link: 'https://github.com/Kokkales/Cloud-Management',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: contractImg,
      title: 'Divorce Service',
      description:
        'University Assignment. A service for Lawyers, Spouses and Notaries to make the divorce process easier.',
      skills: ['React Js', 'Javascript', 'Html', 'Css', 'Java', 'SpringBoot'],
      link: 'https://github.com/Kokkales/HUA.DistributedSystems.22055-22047-22113-Frontend',
      pdfFile: divorcePdf,
      pdfName: 'Documentation',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: travelImg,
      title: 'Travel Agency',
      description:
        'University Assignment. A platform that recommends travel destinations based on preferences and Covid-19 restrictions.',
      skills: ['Java', 'JavaFX'],
      link: 'https://github.com/jtsoukalas/OOPII_22055_22047_22113',
      pdfFile:
        'https://github.com/jtsoukalas/OOPII_22055_22047_22113/tree/newMaster/.readmeFiles',
      pdfName: 'Travel Agency Docs',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: threadImg,
      title: 'OS-Worker Pool',
      description:
        'University Assignment. Parent process creates child processes to perform different tasks and shuts down gracefully.',
      skills: ['C', 'Unix', 'Docker', 'Python'],
      link: 'https://github.com/Kokkales/OS-Worker_Pool',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: dataVisualizationImg,
      title: 'Data Visualization',
      description:
        'University Assignment. Visualizing data after cleaning an Excel dataset.',
      skills: ['React JS', 'Node.js', 'Python', 'SQL'],
      link: 'https://github.com/oduwancheekee/DBS_project',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: dataStructureImg,
      title: 'Circular Queue',
      description: 'University Assignment in Data Structures.',
      skills: ['Java', 'Data Structures'],
      link: 'https://github.com/jtsoukalas/DS_22055_22047_22113',
      pdfFile: cq,
      pdfName: 'Documentation',
      isDownload: false,
      btnMsg: 'Github',
    },
    {
      image: githubImg,
      title: 'Github',
      description: 'Check more projects in my Github account.',
      skills: ['Java', 'Data Structures'],
      link: 'https://github.com/kokkales',
      pdfFile: cq,
      pdfName: 'Documentation',
      isDownload: false,
      btnMsg: 'Visit',
    },
  ];

  // Adjust chunk size based on screen width
  const chunkSize = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;

  const chunkProjects = (projects, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < projects.length; i += chunkSize) {
      chunks.push(projects.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const projectChunks = chunkProjects(projects, chunkSize);

  return (
    // <div class="container-fluid" className=" px-0 mx-0">
    <div
      className="container-fluid mx-0 pb-4 text-center justify-content-top align-items-center"
      id="projects"
      style={{ backgroundColor: '#0b192c' }}
    >
      <Row>
        <Subtitle isSec="1" title="Projects" subtitle="My Work" />
      </Row>
      <Row className="pb-0 mx-3 justify-content-center align-items-center">
        <Carousel
          interval={null}
          // indicators
          className="pb-0 mx-0 w-100"
          style={{ minHeight: '500px' }}
          // nextIcon={<FaChevronRight size={24} color="black" />} // Right arrow
          // prevIcon={<FaChevronLeft size={24} color="black" />} // Left arrow
        >
          {projectChunks.map((chunk, index) => (
            <Carousel.Item key={index} className="w-100">
              <Row className="justify-content-center">
                {chunk.map((project, idx) => (
                  <Col
                    key={idx}
                    xs={12} // Full width on extra small screens
                    sm={6} // Two columns on small screens
                    md={4} // Three columns on medium screens and up
                    className="d-flex justify-content-center text-center"
                  >
                    <Project
                      image={project.image}
                      title={project.title}
                      description={project.description}
                      skills={project.skills}
                      link={project.link}
                      pdfFile={project.pdfFile}
                      pdfName={project.pdfName}
                      isDownload={project.isDownload}
                      btnMsg={project.btnMsg}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
    </div>
    // </div>
  );
}

export default ProjectPage;
