import classes from './ContactForm.module.css';
import React from 'react';

export default function ContactForm(props) {
  const [result, setResult] = React.useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult('Sending....');

    const formData = new FormData(event.target);

    const apiKey = process.env.REACT_APP_API_KEY;
    console.log(process.env);
    formData.append('access_key', apiKey);
    // formData.append('access_key', 'c3c6ff5a-5536-4fc1-ad9b-934061388430');
    // c3c6ff5a-5536-4fc1-ad9b-934061388430

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult('Form Submitted Successfully');
      event.target.reset();
    } else {
      console.log('Error', data);
      setResult(data.message);
    }
  };

  return (
    <div className={classes.contactFormContent}>
      <form onSubmit={onSubmit}>
        <div>
          <div className={classes.titeBox}>
            <h2 className={classes.title}>Contact me</h2>
          </div>
          <div className={classes.emailBox}>
            <label className={classes.contactLabel}>Email</label>
            <input
              type="email"
              name="email"
              required
              className={classes.emailInput}
              placeholder="johndoe@email.com"
            />
          </div>
          <div className={classes.fullNameBox}>
            <label className={classes.contactLabel}>Full Name</label>
            <input
              type="text"
              name="name"
              required
              className={classes.fullNameInput}
              placeholder="John Doe"
            />
          </div>
          <div className={classes.textBox}>
            <label className={classes.contactLabel}></label>
            <textarea
              name="message"
              required
              className={classes.textInput}
              placeholder="Hi I would like to work with you!"
            />
          </div>
          <div className={classes.buttonBox}>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ backgroundColor: '#0B192C', border: '0px' }}
            >
              Let's Work Together!
            </button>
          </div>
        </div>
      </form>
      <span>{result}</span>
    </div>
  );
}
