import MainNavigationBar from '../components/MainNavigationBar';
import MainLayout from '../layouts/MainLayout';
import classes from './HomePage.module.css';
import backgroundImg from '../images/background.png';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/Image';
import linkedin from '../images/linkedin.png';
import instagram from '../images/instagram.png';
import github from '../images/github-sign.png';
import backgroundImage from '../images/whiteBg.jpg';
import testImage from '../images/testImage.jpg';
import testImageTwo from '../images/testImageTwo.jpg';

function HomePage(props) {
  // Define text parts
  const beforeNameText = `Hi! My name is `;
  const nameText = `Konstantinos Kokkalis`;
  const afterNameText = ` and I am a `;
  const jobTitles = ['Programmer', 'Web Developer', 'Software Engineer'];

  // State variables for typing effects
  const [beforeNameTyped, setBeforeNameTyped] = useState(''); // For typing "Hi! My name is"
  const [nameTyped, setNameTyped] = useState(''); // For typing the name
  const [afterNameTyped, setAfterNameTyped] = useState(''); // For typing "and I am a"
  const [jobIndex, setJobIndex] = useState(0); // Track which job title is being typed
  const [isDeleting, setIsDeleting] = useState(false); // For erasing mode
  const [typedJob, setTypedJob] = useState(''); // Current job being typed

  useEffect(() => {
    let typingSpeed = 100; // Adjust typing speed
    let erasingSpeed = 80; // Adjust erasing speed

    const handleTyping = () => {
      if (beforeNameTyped.length < beforeNameText.length) {
        // Type the "Hi! My name is" part first
        setBeforeNameTyped(
          beforeNameText.substring(0, beforeNameTyped.length + 1)
        );
      } else if (nameTyped.length < nameText.length) {
        // Type "Konstantinos Kokkalis" next
        setNameTyped(nameText.substring(0, nameTyped.length + 1));
      } else if (afterNameTyped.length < afterNameText.length) {
        // Type "and I am a" after the name
        setAfterNameTyped(
          afterNameText.substring(0, afterNameTyped.length + 1)
        );
      } else {
        // After the static parts are fully typed, type the dynamic job titles
        const currentJob = jobTitles[jobIndex];
        if (!isDeleting) {
          setTypedJob((prev) => currentJob.substring(0, prev.length + 1));
          if (typedJob === currentJob) {
            setTimeout(() => setIsDeleting(true), 1000); // Wait before erasing
          }
        } else {
          setTypedJob((prev) => currentJob.substring(0, prev.length - 1));
          if (typedJob === '') {
            setIsDeleting(false);
            setJobIndex((prevIndex) =>
              prevIndex === jobTitles.length - 1 ? prevIndex : prevIndex + 1
            );
          }
        }
      }
    };

    const timeoutId = setTimeout(
      handleTyping,
      isDeleting ? erasingSpeed : typingSpeed
    );

    return () => clearTimeout(timeoutId); // Clean up
  }, [
    beforeNameTyped,
    nameTyped,
    afterNameTyped,
    typedJob,
    isDeleting,
    jobIndex,
  ]);

  return (
    <div
      id="home"
      // class="container-fluid"
      style={{
        backgroundColor: 'blue',
        // backgroundImage: `url(${backgroundImage})`,
        backgroundImage: `url(${testImageTwo})`,
        width: '100%',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className="container-fluid px-0 mx-0 text-center justify-content-center align-items-start d-flex vh-100"
    >
      <Row className="px-0 mx-0">
        <Col className="px-0 mx-0">
          <p
            className={classes.introText}
            style={{
              paddingTop: '10rem',
            }}
          >
            {/* Display the progressively typed parts */}
            {beforeNameTyped}
            <br />
            <span className={classes.name}>{nameTyped}</span>
            <br />
            {afterNameTyped}
            <br />
            {/* Display dynamically typed job titles */}
            <span className={classes.role}>{typedJob}</span>
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default HomePage;
