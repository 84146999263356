import React from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Subtitle from '../components/Subtitle';
import SkillsBar from '../components/SkillsBar';

function SkillsPage(props) {
  return (
    <div
      id="skills"
      className="container-fluid mx-0 pb-4 text-center justify-content-top align-items-center"
      style={{}}
    >
      <Row className="pb-0 mx-0" style={{}}>
        <Col className="px-0 mx-0">
          <Subtitle title="Skills" subtitle="what do I know?"></Subtitle>
        </Col>
      </Row>
      <Row className="pb-0 mx-0 justify-content-center align-items-center">
        <div className=" mx-2 px-2 col-12 col-md-12 col-lg-4" style={{}}>
          <p>
            I gained a lot of skills and experience in certain technologies
            during my studies, which have helped me grow both personally and
            professionally. These experiences have sharpened my problem-solving
            abilities and taught me how to approach challenges effectively.
            Working on various projects has given me valuable insights into
            teamwork, time management, and adapting to new situations, all of
            which have contributed to my development as a more capable and
            resourceful individual.
          </p>
          <SkillsBar skillTitle="Greek" skillRate="100%" skillValue="100" />
          <SkillsBar skillTitle="English" skillRate="90%" skillValue="90" />
          <SkillsBar skillTitle="German" skillRate="30%" skillValue="30" />
        </div>
        <div className=" mx-2 px-2 py-0 col-12 col-md-12 col-lg-4" style={{}}>
          <SkillsBar skillTitle="Java" skillRate="85%" skillValue="85" />
          <SkillsBar skillTitle="Python" skillRate="70%" skillValue="70" />
          <SkillsBar skillTitle="C" skillRate="67%" skillValue="67" />
          <SkillsBar
            skillTitle="SQL | Databases"
            skillRate="80%"
            skillValue="80"
          />
          <SkillsBar skillTitle="Networks" skillRate="65%" skillValue="65" />
          <SkillsBar
            skillTitle="AI | Machine Learning"
            skillRate="70%"
            skillValue="70"
          />
          <SkillsBar
            skillTitle="Information Systems"
            skillRate="75%"
            skillValue="75"
          />
          <SkillsBar skillTitle="What is next..?" skillRate="0%" />
        </div>
      </Row>
    </div>
  );
}

export default SkillsPage;
