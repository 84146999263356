export default function Project(props) {
  return (
    <div
      className="card pt-2 my-2"
      style={{ width: '18rem', textAlign: 'center' }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '9rem', height: '9rem', margin: 'auto auto' }}
      >
        <img
          className="card-img-top"
          src={props.image}
          alt="Card image cap"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title">
          {props.title}
          {/* Learning, Parameter Tuning and Model Ensembles (Invited Paper) */}
        </h5>
        <p className="card-text">{props.description}</p>
        <a
          href={props.link}
          className="btn btn-primary"
          style={{ backgroundColor: '#0B192C', border: '0px' }}
        >
          {props.btnMsg}
        </a>
      </div>
    </div>
  );
}
