import AboutCircle from '../components/projects/AboutCircle';
import classes from './AboutPage.module.css';
import cv from '../otherFiles/CV KOKKALIS KONSTANTINOS.pdf';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import myFace from '../images/IMG_20240914_103440.jpg';
import Subtitle from '../components/Subtitle';
import battery from '../images/battery.png';
import Image from 'react-bootstrap/esm/Image';
import Project from '../components/projects/Project';

function ResearchPage(props) {
  return (
    <div
      id="research"
      className="container-fluid mx-0 pb-4 text-center justify-content-top align-items-center"
      style={{ backgroundColor: '#0b192c' }}
    >
      <Row className="pb-0 mx-0" style={{}}>
        <Col className="px-0 mx-0">
          <Subtitle
            isSec="1"
            title="Research"
            subtitle="My contribution to the world"
          ></Subtitle>
        </Col>
      </Row>
      <Row className=" pb-0 mx-0 justify-content-center align-items-center">
        {/* First column: Container for image */}
        <div
          className=" mx-2 px-0 col-12 col-md-12 col-lg-3"
          style={{ width: 'auto' }}
        >
          <div // Use Bootstrap classes for responsiveness
            style={{ width: 'auto' }}
          >
            <Project
              image={battery}
              title="Predicting the Lifespan of Lithium-Ion Batteries Using Machine Learning, Parameter Tuning and Model Ensembles (Invited Paper)"
              description=""
              // skills={['Java', 'Data Structures']}
              link="https://ieeexplore.ieee.org/document/10730359"
              // pdfFile={cq}
              // pdfName="Documentation"
              // isDownload={false}
              btnMsg="Article"
            />
          </div>
        </div>

        {/* Second column: Text column */}
        <div
          className="col-12 col-md-12 col-lg-6 text-center align-items-center justify-content-center"
          style={{}}
        >
          <Row className="text-center justify-content-center align-items-center">
            <div style={{ color: 'white' }} class="container text-white">
              <p style={{ color: 'white' }} class="font-weight-bold">
                Predicting the Lifespan of Lithium-Ion Batteries Using Machine
                Learning, Parameter Tuning and Model Ensembles (Invited Paper)
              </p>
              <p style={{ color: 'white' }}>
                <strong>Authors:</strong> Konstantinos Kokkalis; Christos
                Chronis; Elena Politi; George Dimitrakopoulos; Iraklis Varlamis
              </p>
              <p style={{ color: 'white' }}>
                <strong>Published in:</strong> 2024 IEEE 10th International
                Conference on Big Data Computing Service and Machine Learning
                Applications (BigDataService)
              </p>
            </div>
          </Row>
        </div>
      </Row>
    </div>
  );
}
export default ResearchPage;
