import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ProjectPage from './pages/ProjectPage';
import MainLayout from './layouts/MainLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import EducationPage from './pages/EducationPage';
import ResearchPage from './pages/ResearchPage';
import SkillsPage from './pages/SkillsPage';
import ExperiencePage from './pages/ExperiencePage';
import ServicesPage from './pages/ServicesPage';

function App() {
  return (
    <MainLayout>
      <HomePage />
      <AboutPage />
      <ServicesPage />
      {/* <EducationPage /> */}
      {/* <ExperiencePage /> */}
      <ProjectPage />
      <SkillsPage />
      <ResearchPage />
      <ContactPage />
    </MainLayout>
    // <Routes>
    //   <Route path="/" element={<HomePage />} />{' '}
    //   <Route path="/about" element={<AboutPage />} />{' '}
    //   <Route path="/contact" element={<ContactPage />} />{' '}
    //   <Route path="/project" element={<ProjectPage />} />{' '}
    // </Routes>
  );
}

export default App;
