import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Subtitle from '../components/Subtitle';
import Project from '../components/projects/Project';
import webDevelopmentImg from '../images/app-development.png';
import technicalSupImg from '../images/technical-support.png';

function ServicesPage(props) {
  return (
    <div
      id="services"
      className="container-fluid mx-0 pb-4 text-center justify-content-top align-items-center"
      // style={{ backgroundColor: 'grey' }}
    >
      <Row className="pb-0 mx-0 text-center justify-content-center align-items-center">
        <div
          id="services"
          className="container text-center"
          // style={{ backgroundColor: 'grey' }}
        >
          <Row className="pb-0 mx-0 text-center justify-content-center align-items-center">
            <Col className="px-0 mx-0 text-center">
              <Subtitle title="Services" subtitle="what do I offer"></Subtitle>
            </Col>
          </Row>
          <Row
            className="pb-0 mx-0 text-center justify-content-center align-items-center"
            // style={{ backgroundColor: 'red' }}
          >
            <Col
              className="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center text-center"
              // style={{ backgroundColor: 'green', height: '100%' }}
            >
              <Project
                image={webDevelopmentImg}
                title="Web Development"
                btnMsg="Contact"
                link="#contact"
              />
            </Col>
            <Col
              className="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center text-center"
              // style={{ backgroundColor: 'yellow', height: '100%' }}
            >
              <Project
                image={technicalSupImg}
                title="Technical Support"
                btnMsg="Contact"
                link="#contact"
              />
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default ServicesPage;
