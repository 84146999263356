import Container from 'react-bootstrap/esm/Container';

function Subtitle(props) {
  return (
    <div
      className="container-fluid px-0 mx-0 text-center align-items-center justify-content-center"
      style={{
        paddingTop: '4rem',
        paddingBottom: '1rem',
      }}
    >
      {props.isSec ? (
        <>
          <h2 style={{ color: 'white' }}>{props.title}</h2>
          <h5 style={{ color: 'white' }}>-{props.subtitle}-</h5>
        </>
      ) : (
        <>
          <h2>{props.title}</h2>
          <h5>-{props.subtitle}-</h5>
        </>
      )}
    </div>
  );
}

export default Subtitle;
